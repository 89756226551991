.vue-select
  position: relative
  width: 100%
  margin-top: 40px
  +mq-sm
    margin-top: 10px
    width: 320px
  &.isActive
    input
      border-bottom: 2px solid #9B9B9B!important
      &:focus
        border-bottom-color: #9B9B9B!important
  .vs__dropdown-toggle
    height: 50px
    background: transparent
    width: 100%
    appearance: none
    cursor: pointer
    border: 0
    &:focus
      border: 0
      outline: none
    option
      padding: 10px 20px
  input
    transition: 0s
    border-bottom: 1px solid #9B9B9B!important
    border-radius: 0
    &:focus
      border-bottom-color: #9B9B9B!important
  .vs__actions
    position: absolute
    right: 0
    bottom: 20px
    z-index: 5
  .vs__search,
  .vs__selected
    margin: 0!important
    padding: 0!important
  .vs__selected
    width: 100%
    position: absolute
    left: 0
    bottom: 8px
    z-index: 4
  .vs__selected,
  li
    font-family: 'Unbounded-Light', sans-serif
    color: #FFF
    font-size: 16px
    font-weight: 300
    line-height: 200%
  li
    padding: 7px 20px
  .vs__dropdown-menu
    border: 0
    background: $text-1!important
    overflow-x: auto
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4)
    height: 455px
    max-height: unset
    +mq-sm
      height: 340px
  .vs__dropdown-option--highlight
    background: #9B9B9B!important
    color: white
    padding: 12px 20px
  .vs__open-indicator
    fill: white
.get-form
  &.contact
    .vue-select
      .vs__selected
        line-height: 200%!important
