.help--type_error
  position: absolute
  color: #FF0000
  z-index: 5
  font-size: 12px
  left: 0
  bottom: -20px
  font-family: 'Unbounded-Light', sans-serif
  font-weight: 300

.get-form
  \:focus
    outline: none

  input
    width: 100%
    letter-spacing: 1px
    border: 0
    border-radius: 0
    font-weight: 300
    font-family: "Unbounded-Light", sans-serif
    color: white
    background-color: #0E0E0E
    height: 43px
    font-size: 16px
  input::placeholder
    color: white
  .icon-close
    cursor: pointer
    &:before
      color: white
      top: 1px
      font-size: 12px
      position: relative
  .effect-line-input
    border-top: 0
    padding: 7px 0
    border-left: 0
    border-bottom: 1px solid #9B9B9B
  .effect-line-input
    ~ .focus-border
      position: absolute
      bottom: -1px
      left: 0
      width: 0
      height: 2px
      background-color: #FF0000
      transition: 0.4s

    &:focus ~ .focus-border
      width: 100%
      transition: 0.4s

  .is-value
    transition: 0.4s
    .focus-border
      width: 100%
      background-color: #9B9B9B
  .is-erors
    transition: 0.4s
    .focus-border
      width: 100%
      background-color: #FF0000
  &.contact
    input
      background-color: transparent
    .vue-select
      margin-top: 0
      width: 100%
      .vs__selected
        line-height: 130%
    //.radio-wrapper input[type=checkbox]:hover:not(:checked):not(:disabled)
    //  border: 3px solid white
    //@media (min-width: 768px)
    //  .radio-wrapper input[type=checkbox]
    //    border: 3px solid white
