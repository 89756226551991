.btn
  &--
    &arrow
      display: flex
      align-items: center
      cursor: pointer
      +mq-till-md
        .arrows-svg
          width: 26px
          height: 26px
      .ds-caption
        padding-right: 15px
      *
        transition: 0.8s
