.footer
  width: 100%
  height: auto
  background: #000
  position: relative
  z-index: 10
  padding: 30px 0
    bottom: 20px
  +mq-md
    padding: 50px 0
  +mq-xl
    padding: 90px 0
.social-wrap-footer
  @media (max-width: 1500px)
    margin-top: 30px
