.cards
  &-
    &technologies
      &.hover
        &:hover
          cursor: pointer
      .icon
        +mq-till-sm
          display: flex
          justify-content: center
      svg
        *
          transition: 0.8s!important
      +mq-xl
        &:hover
          .line--technologies
            &:before
              width: 100%
          .desc
            color: white
            opacity: 1
      &__
        &text
          height: 100px
          overflow: hidden

    &solutions
      background-color: #000
      width: 100%
      height: 100%
      transition: 0.8s
      display: flex
      justify-content: space-between
      flex-direction: column
      padding: 35px
        top: 0
      +mq-lg
        padding: 50px
          top: 0
      +mq-xl
        &:hover
          cursor: pointer
          transform: scale(1.05)
      &__
        &img
          width: 100%
          display: flex
          align-items: center
          height: 143px
          +mq-lg
            height: 210px
          img
            object-fit: contain
            width: auto
            height: auto
            max-height: 40px
            +mq-lg
              max-height: 60px
    &product
      padding: 30px
      height: 100%
      width: 100%
      border: 1px solid rgba(54, 54, 54, 0.50)
      transition: 0.8s
      display: flex
      flex-direction: column
      justify-content: space-between
      +mq-md
        padding: 40px
      +mq-xl
        padding: 50px
        &:hover
          border: 1px solid black
          background-color: black
    &industries
      width: 100%
      height: 720px
      position: relative
      display: flex
      align-items: center
      .right-content
        max-width: 545px
        margin-left: 5.604vw
    &servives
      width: 100%
      position: relative
      //max-width: 506px
      overflow: hidden
      transition: 0.45s
      display: flex
      flex-direction: column
      justify-content: flex-end
      height: 454px
      +mq-xl
        height: 667px
        &:hover
          .cards-servives__hover
            bottom: 0
            .title
              padding-bottom: 30px
          .line--grey
            width: 100%
          .cards-servives__img
            transform: scale(1.1)
          &:after
            opacity: 1
      &.services
        height: auto
        +mq-md
          height: 500px
        +mq-lg
          height: 667px
        &:hover
          +mq-till-md
            .wtitle
              padding-bottom: 20px
          .cards-servives__hover-service-page
            +mq-md
              max-height: 400px
            +mq-lg
              max-height: 600px
            +mq-till-md
              grid-template-rows: 1fr
            .ds-caption
              opacity: 1


      &__
        &hover-service-page
          position: relative
          transition: 2s ease-out
          +mq-md
            height: 100%
            overflow: hidden
            max-height: 80px
          +mq-lg
            max-height: 90px
          +mq-till-md
            display: grid
            grid-template-rows: 0fr
            transition: grid-template-rows 800ms
            .text
              overflow: hidden
        &wrap
          z-index: 10
          position: relative
          padding: 35px
          +mq-lg
            padding: 50px
          +mq-xl
            padding: 40px
          @media (min-width: 1440px)
            padding: 50px
        &outliner
          padding: 34px
          +mq-xl
            padding: 54px 50px
        &link
          position: absolute
          left: 0
          top: 0
          width: 100%
          height: 100%
          z-index: 20
        &hover
          width: 100%
          transition: 1.2s
          position: absolute
          z-index: 10
          +mq-xl
            bottom: -145px
        &img
          transition: 1.2s
          object-fit: cover
          width: 100%
          height: 100%
          position: absolute
          left: 0
          top: 0
      &:before,
      &:after
        content: ''
        position: absolute
        left: 0
        top: 0
        z-index: 5
        width: 100%
        height: 100%
        transition: 1.2s
      &:before
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.79) 27.82%, rgba(0, 0, 0, 0.00) 77.26%)
      &:after
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.79) 27.82%, rgba(0, 0, 0, 0.00) 77.26%)
        opacity: 0
      &.services
        &:before
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.40) 27.82%, rgba(0, 0, 0, 0.00) 77.26%)

.technologies-page
  .cards-technologies
    +mq-till-lg
      .icon
        justify-content: flex-start
        svg
          width: 54px
          height: 54px
      .line--technologies
        &:before
          transform: unset
          left: 0
.cards-technologies
  &.modal-technologies
    .icon
      justify-content: flex-start
    .line--technologies
      &:before
        transform: unset
        left: 0
.nav-items
  display: inline-block
  //flex-direction: column
  &__
    &title
      margin-bottom: 2.344vw
      &:last-child
        margin-bottom: 0
      &.active
        position: relative
        &:before
          content: ''
          height: 1px
          position: absolute
          background-color: #9B9B9B
          top: 50%
          transform: translateY(-50%)
          left: -110px
          width: 100px
          +mq-xl
            width: 51px
            left: -70px
          +mq-mac
            left: -100px
        .ds-caption
          opacity: 1


.page-responsibility
  ul
    margin: 0
    padding-left: 20px
.wrap-legal
  max-width: 911px
  width: 100%

.first-block-legal
  +mq-xl
    max-width: 382px
