$MQ_INCLUDE_XL: true;

$TONE_GENERATE_THEME: false;

  /////////////////color caption$color-group
$text-1: #000000 !default;
$text-2: #F3F5FA !default;
$text-3: #Ffffff !default;
$text-4: #5C2482 !default;
$text-5: #454545 !default;
$text-6: #8D8D8D !default;

$color-group: () !default;
$color-group: append($color-group, ('black', $text-1));
$color-group: append($color-group, ('lgrey', $text-2));
$color-group: append($color-group, ('white', $text-3));
$color-group: append($color-group, ('purple', $text-4));
$color-group: append($color-group, ('grey', $text-5));
$color-group: append($color-group, ('lgrey', $text-6));

  // Merge FONT SIZE
$type-font-size-37xl: 37px;
$type-font-size-3xl: 35px;
$type-font-size-30xl: 30px;
$type-font-size-2xl: 28px;
$type-font-size-1sm: 15px;
$type-font-size-1xs: 13px;
$type-font-size-11xs: 11px;
$type-font-size-2md: 18px;
$type-font-size-3md: 19px;
$type-font-size-4xl: 40px;
$type-font-size-6xl: 60px;



$type-font-size-map: ();
$type-font-size-map: map-merge($type-font-size-map, ('3xl': $type-font-size-3xl));
$type-font-size-map: map-merge($type-font-size-map, ('37xl': $type-font-size-37xl));
$type-font-size-map: map-merge($type-font-size-map, ('2xl': $type-font-size-2xl));
$type-font-size-map: map-merge($type-font-size-map, ('30xl': $type-font-size-30xl));
$type-font-size-map: map-merge($type-font-size-map, ('1sm': $type-font-size-1sm));
$type-font-size-map: map-merge($type-font-size-map, ('1xs': $type-font-size-1xs));
$type-font-size-map: map-merge($type-font-size-map, ('11xs': $type-font-size-11xs));
$type-font-size-map: map-merge($type-font-size-map, ('3md': $type-font-size-3md));
$type-font-size-map: map-merge($type-font-size-map, ('4xl': $type-font-size-4xl));
$type-font-size-map: map-merge($type-font-size-map, ('6xl': $type-font-size-6xl));
$type-font-size-map: map-merge($type-font-size-map, ('2md': $type-font-size-2md));


///////////////// Weight group
$weight-lite: 300;
$weight-regular: 400;
$weight-medium: 500;

$font-weight-group: () !default;
$font-weight-group: append($font-weight-group, ('w_l', $weight-lite));
$font-weight-group: append($font-weight-group, ('w_r', $weight-regular));
$font-weight-group: append($font-weight-group, ('w_m', $weight-medium));

$mq-size-mac: 1720px !default;

$MQ_INCLUDE_XS: true;

$mq-size-map: ();
$mq-size-map: map-merge($mq-size-map, ('mac': $mq-size-mac));
$mq-query-mac: unquote("min-width: #{$mq-size-mac}") !default;
$mq-query-till-mac: unquote("max-width: #{$mq-size-mac - 1}") !default;



@mixin mq-mac {
  @media ($mq-query-mac) {
    @content;
  }
}
@mixin mq-till-mac {
  @media ($mq-query-till-mac) {
    @content;
  }
}
