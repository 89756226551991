.top-link
  transition: all 0.25s ease-in-out
  position: fixed
  right: 0
  cursor: pointer
  align-items: center
  justify-content: center
  z-index: 99
  margin: 0 1.5em 1.5em 0
  border-radius: 50%
  background-color: black
  visibility: hidden
  opacity: 0
  display:  inline-flex
  width: 30px
  height: 30px
  bottom: 15px
  @media (min-width: 500px)
    bottom: 41px
  +mq-sm
    bottom: 42px
  +mq-md
    bottom: 54px
    width: 50px
    height: 50px
    display: inline-flex
  &:before
    content: ''
    color: $text-3
    position: absolute
    background-image: url("./../img/Vector.svg")
    background-repeat: no-repeat
    background-position: center
    background-size: contain
    transition: 0.3s
    width: 8px
    height: 8px
    left: 50%
    top: 50%
    transform: translate(-50%, -50%) rotate(-180deg)
    +mq-sm
      width: 12px
      height: 12px
  +mq-xl
    &:hover
      &:before
        animation: pulse 2s infinite
@keyframes pulse
  0%
    transform: translate(-50%, -50%) rotate(-180deg)

  50%
    transform: translate(-50%, 0) rotate(-180deg)

  100%
    transform: translate(-50%, -50%) rotate(-180deg)

