.line
  &--
    &h
      background: #363636
      width: 1px
      height: 26px
      margin: 0 26px
    &technologies
      height: 1px
      background-color: #515151
      position: relative
      width: 100%
      transition: 0.8s
      &:before
        content: ''
        position: absolute
        transition: 0.8s
        top: 0
        height: 1px
        background-color: #9B9B9B
        width: 78px
        left: 0
        +mq-till-sm
          left: 50%
          transform: translateX(-50%)


    &grey
      height: 1px
      background-color: #515151
      position: relative
      transition: 0.8s
      width: 100%
      +mq-xl
        width: 0
    &legal-v
      width: 1px
      background-color: #363636
      position: relative
      height: auto
      margin: 0 15px
      @media (min-width: 1600px)
        margin: 0 3vw
      +mq-till-md
        display: none
    &legal
      height: 1px
      background-color: #363636
      position: relative
      width: 100%
      margin: 50px 0
      display: none
      +mq-md
        display: block
      +mq-xl
        margin: 80px 0
//&:after
  //  content: ""
  //  display: block
  //  height: 1px
  //  left: 0
  //  position: absolute
  //  transition: width 0.3s ease 0s, left 0.3s ease 0s
  //  background-color: white
  //  width: 100%
