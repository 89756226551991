.circl-front
  .ds-caption
    max-width: 550px

#circle-svg
  cursor: pointer
  @extend .flex_center
  *
    transition: 0.8s!important
  .circl-radius
    width: 52.847px
    height: 52.847px
    +mq-md
      width: 80.847px
      height: 80.847px
    +mq-xl
      width: 110px
      height: 110px


  .circle-svg-arrows
    position: absolute
    @extend .pos_abs_center
    width: 28px
    height: 28px
    +mq-md
      width: 40px
      height: 40px
    +mq-xl
      width: 57px
      height: 57px


.page-404
  #circle-svg
    cursor: pointer
    transform: rotate(90deg)
    .circl-radius
      width: 109.847px
      height: 109.847px
      +mq-xl
        width: 193.153px
        height: 193.153px
    .circle-svg-arrows
      width: 55.887px
      height: 55.887px
      +mq-xl
        width: 98.271px
        height: 98.271px
.services-page
  #circle-svg
    +mq-till-xl
      width: 81px
      height: 81px
      margin: auto
    cursor: pointer
    transform: rotate(-90deg)
    .circl-radius
      width: 80.847px
      height: 80.847px
      +mq-xl
        width: 110px
        height: 110px
    .circle-svg-arrows
      width: 40px
      height: 40px
      +mq-xl
        width: 57px
        height: 57px
