.#{$DERMIS_PREFIX}caption
  text-decoration: none
  font-family: "Unbounded-Regular", sans-serif
  line-height: 1
  +mq-lg
    transition: 0.5s
  &--
    &size_
      @each $size, $value in $type-font-size-map
        &#{$size}
          +mq-adaptive
            font-size: #{$value}
    @each $color, $value  in $color-group
      &#{$color}, &c_#{$color}
        +mq-both-adaptive
          color: #{$value}
    @each $key, $value  in $font-weight-group
      &#{$key}
        font-weight: #{$value}
    @each $key, $value in $type-face-map
      &#{$key}
        font-family: #{$value}
    @each $height, $value in $font-height-group
      &h_#{$height}
        +mq-both-adaptive
          line-height: $value
    &w_l
      +mq-both-adaptive
        font-weight: 300
        font-family: "Unbounded-Light", sans-serif
    &w_r
      +mq-both-adaptive
        font-weight: 400
        font-family: "Unbounded-Regular", sans-serif
    &w_m
      +mq-both-adaptive
        font-weight: 500
        font-family: "Unbounded-Medium", sans-serif

    &size
      &_
        &2vw
          +mq-both-adaptive
            font-size: 1.823vw
        &3vw
          +mq-both-adaptive
            font-size: 0.990vw
        &8vw
          +mq-both-adaptive
            font-size: 4.323vw
    &gradient
      background: linear-gradient(139deg, #A9A9A9 -21.52%, rgba(142, 142, 142, 0.00) 114.84%)
      background-clip: text
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
    &gradient_v2
        background: linear-gradient(132deg, #A9A9A9 -37.18%, rgba(142, 142, 142, 0.00) 189.18%)
        background-clip: text
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
    &upp
      text-transform: uppercase
    &op
      &-
        &3
          +mq-both-adaptive
            opacity: 0.3
        &4
          +mq-both-adaptive
            opacity: 0.4
        &5
          +mq-both-adaptive
            opacity: 0.5
        &6
          +mq-both-adaptive
            opacity: 0.6

    &quote
      position: relative
      padding-top: 15px
      +mq-md
        padding: 0 50px
      &:before
        content: ''
        position: absolute
        background: #363636
        left: 0
        top: 0
        width: 100%
        height: 1px
        +mq-md
          width: 1px
          height: 100%

    &hover
      &_
        &def
          cursor: pointer
          +mq-xl
            transition: 0.3s
            &:hover
              transition: 0.3s
              color: $text-2
              opacity: 1
              &:before
                color: $text-2

        &line
          position: relative
          text-decoration: none
          cursor: pointer
          &:after
            background: none repeat scroll 0 0 transparent
            bottom: -5px
            content: ""
            display: block
            height: 2px
            left: 50%
            position: absolute
            transition: width 0.3s ease 0s, left 0.3s ease 0s
            width: 0
          +mq-xl
            &:hover:after
              width: 100%
              left: 0
          &.black::after
            background-color: black
          &.white::after
            background-color: white
