.sitemap
  position: relative
  display: flex
  flex-wrap: wrap
  +mq-md
    height: 100%
    padding: 0 15px
  +mq-lg
    height: 900px
  &__
    &item-double
      left: 25px
      position: absolute
      top: 0
      width: 350px
      +mq-md
        width: 550px
        left: 35px
      +mq-lg
        left: 0
      +mq-xl
        width: 550px
    &item
      position: absolute
      left: 25px
      top: 0
      +mq-md
        left: 35px
      +mq-lg
        left: 0
    &cols
      display: flex
      flex-direction: column
      //width: 30%
      height: 100%
      position: relative
      width: 370px
      +mq-xl
        width: 420px
      @media (min-width: 1400px)
        width: 443px
      &.mobile
        display: flex
        +mq-lg
          display: none
      &.desctop
        display: none
        +mq-lg
          display: flex
    &header
      align-items: center
      position: relative
      top: 30px
      height: 56px
      width: 100%
      margin-left: 15px
      display: none
      +mq-lg
        display: flex
      +mq-xl
        margin-left: 30px
    &cols-mobile
      position: absolute
      left: 0
      top: 15px
      width: 100%
      height: auto
      &.v2
        top: unset
        transform: translateY(10px)
    &title
      color: #FFF
      font-family: 'Unbounded-Regular', sans-serif
      font-style: normal
      font-weight: 400
      line-height: 180%
      text-transform: uppercase
      padding: 0 10px
      font-size: 13px
      top: -9px
      +mq-md
        font-size: 16px
        top: -13px
      +mq-lg
        top: 0
      +mq-till-lg
        height: 0
        position: relative
        margin-left: 15px
      &.v2
        top: -16px
        +mq-md
          top: -20px
      &.v3
        top: -26px
        +mq-md
          top: -30px
      +mq-xl
        font-size: 20px
      @media (min-width: 1400px)
        padding: 0 20px
    &link
      color: #8D8D8D
      font-family: 'Unbounded-Regular', sans-serif
      font-weight: 400
      line-height: 180%
      text-transform: uppercase
      text-decoration: none
      border-radius: 5px
      border: 1px solid #3D3D3D
      z-index: 10
      position: relative
      transition: 0.5s
      padding: 10px
      font-size: 11px
      +mq-md
        font-size: 14px
      +mq-lg
        font-size: 16px
        padding: 20px
      &:hover
        border-color: white
        color: white
        cursor: pointer
.break-text
  word-break: break-all
