.img
  &--
    &logo
      position: relative
      z-index: 10
      width: 168px
      height: 16px
      +mq-md
        width: 199px
        height: 19px

    &industries
      position: relative
      width: 100%
      max-width: 910px
      display: flex
      justify-content: center
      align-items: center
      height: 190px
      +mq-md
        height: 300px
      .title
        text-align: center
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        z-index: 5
      +mq-lg
        position: absolute
        left: 0
        top: 0
        height: 100%
        width: 50%
      img
        height: 100%
        width: 100%
        object-fit: cover
      &:before
        content: ''
        position: absolute
        left: 0
        top: 0
        z-index: 5
        width: 100%
        height: 100%
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.69) 24.36%, rgba(0, 0, 0, 0.00) 121.25%), linear-gradient(0deg, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.53) 100%)
    &about-main
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      &:before
        content: ''
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        background: linear-gradient(0deg, rgba(14, 14, 14, 0.39) 0%, rgba(14, 14, 14, 0.39) 100%), linear-gradient(359deg, #0E0E0E 23.19%, #0E0E0E 40.92%, rgba(14, 14, 14, 0.00) 87.59%)
      img
        width: 100%
        height: 100%
        object-fit: cover
      &.desctop
        height: 100%
        max-height: 1400px
        display: none
        +mq-md
          display: block
      &.about
        max-height: 1150px
        &:before
          content: ''
          position: absolute
          left: 0
          top: 0
          width: 100%
          height: 100%
          background: linear-gradient(0deg, rgba(14, 14, 14, 0.66) 0%, rgba(14, 14, 14, 0.66) 100%), linear-gradient(342deg, #0E0E0E 12.45%, #0E0E0E 35.21%, rgba(14, 14, 14, 0.00) 100%)
      &.mobile
        height: auto
        +mq-md
          display: none
    &konigstein
      width: 199px
      height: 38.629px
      +mq-md
        width: 253.558px
        height: 49.22px
    &keil
      object-fit: contain
      width: 148.665px
      height: 39.8px
      +mq-md
        width: 189.423px
        height: 50.712px
    &integral_clinical
      width: 94.784px
      height: 62.154px
      +mq-md
        width: 120.77px
        height: 79.194px
    &selene
      object-fit: contain
      width: 165.053px
      height: 50.335px
      +mq-md
        width: 210.304px
        height: 64.135px
