$spin-green: $text-3

.loader-wrapper
  height: 60px
  position: relative


.custom-loader
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: inline-flex
  &:before
    content: ''
    border-radius: 50%
    border-bottom: 3px solid $spin-green
    border-top: 3px solid $spin-green
    border-left: 3px solid transparent
    border-right: 3px solid transparent
    width: 30px
    height: 30px
    display: block
    margin: auto
    animation: spin 2s linear infinite

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

.loading-mask
  position: absolute
  z-index: 100
  left: 0
  top: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.3)
  &--index-500
    z-index: 500
.contact-form
  select
    height: 63px
  label
    padding-top: 5px

  input
    height: 63px
  .ds-caption
    font-weight: 500!important


