.thx_modal
  background-color: #0E0E0E!important
  padding: 30px
  position: relative
  border-radius: 0!important
  +mq-md
    padding-top: 50px
    padding-bottom: 65px
.vm--modal
  border-radius: 0!important
.vm--overlay
  background: rgba(0, 0, 0, 1) !important
.popup-inner__close-popup
  width: 17px
  height: 17px
  cursor: pointer
  position: absolute
  z-index: 5
  display: flex
  align-items: center
  justify-content: center
  transition: 0.5s linear
  top: 20px
  right: 18px
  +mq-md
    right: 30px
    top: 30px
  &:after,
  &:before
    position: absolute
    content: ""
    width: 17px
    height: 3px
    background-color: white
  &:before
    animation: close 2s ease-out infinite
  &:after
    animation: close-left 2s ease-out infinite
@keyframes close
  0%
    transform: rotate(0)
  50%
    transform: rotate(-45deg)
  100%
    transform: rotate(0)
@keyframes close-left
  0%
    transform: rotate(0)
  50%
    transform: rotate(45deg)
  100%
    transform: rotate(0)

//////////////////
.v-modal-technologies,
.v-modal-topic,
.v-modal-consultation
  background-color: #0E0E0E!important
  overflow: auto!important
  //padding: 50px!important
  border-radius: 0!important
  width: 100%!important
  top: 50%!important
  margin: auto!important
  left: 0!important
  right: 0!important
  transform: translateY(-50%)
  max-width: 1000px!important
  max-height: 100%
  height: 100%!important
  +mq-sm
    height: auto!important
    max-height: 90%
.modal-form-community
  .wrap
    padding: 30px
    @media (min-width: 1680px)
      padding: 50px
    .outliner
      margin-top: 10px
      +mq-sm
        margin-top: 20px
      +mq-md
        margin-top: 20px
      @media (min-width: 1680px)
        margin-top: 30px
    .wrap-top
      margin-top: 15px
      +mq-md
        margin-top: 20px
      @media (min-width: 1680px)
        margin-top: 30px
.v-modal-topic
  max-width: 1200px!important
  .wrap
    padding: 30px
    +mq-md
      padding: 40px
.v-modal-technologies
  max-width: 1200px!important
  .wrap
    padding: 40px
  +mq-till-lg
    .icon
      svg
        width: 54px
        height: 54px
  .modal-mobile
    display: block
    +mq-xl
      display: none
    //@media (min-width: 1680px)
      display: block
.radio-topic
  margin: 10px 0
  +mq-lg
    margin: 15px 0
  .text
    padding-left: 0
    letter-spacing: 1px
    font-weight: 300
    font-family: "Unbounded-Light",sans-serif
    color: #fff
    font-size: 16px
    +mq-lg
      &:hover
        cursor: pointer

  label
    float: left

  .custom-radio [type="radio"]
    &:checked, &:not(:checked)
      position: absolute
      left: -9999px

    &:checked + .custom-radio__label, &:not(:checked) + .custom-radio__label
      position: relative
      cursor: pointer
      line-height: 20px
      display: inline-block
      padding-left: 20px
      +mq-lg
        //padding-left: 38px

    &:checked + .custom-radio__label:before, &:not(:checked) + .custom-radio__label:before
      content: ''
      position: absolute
      //left: 3px
      //top: 3px
      //width: 26px
      //height: 26px
      //border: 2px solid #fff
      //border-radius: 100%
      //background: #fff

      left: 0px
      top: 4px
      width: 14px
      height: 14px
      border: 2px solid #fff
      border-radius: 100%
      background: #fff

    &:checked + .custom-radio__label:after
      content: ''
      position: absolute
      border-radius: 100%
      -webkit-transition: all .2s ease
      transition: all .2s ease
      //width: 16px
      //height: 16px
      //background: black
      //top: 8px
      //left: 8px

      width: 8px
      height: 8px
      background: #0e0e0e
      top: 7px
      left: 3px

    &:not(:checked) + .custom-radio__label:after
      content: ''
      position: absolute
      border-radius: 100%
      -webkit-transition: all .2s ease
      transition: all .2s ease
      opacity: 0
      -webkit-transform: scale(0)
      transform: scale(0)
      //width: 16px
      //height: 16px
      //background: black
      //top: 8px
      //left: 8px

      width: 8px
      height: 8px
      background: #0e0e0e
      top: 7px
      left: 3px

    &:checked + .custom-radio__label:after
      opacity: 1
      -webkit-transform: scale(1)
      transform: scale(1)
.non-bord
  .effect-line-input~.focus-border
    background-color: transparent!important
  .effect-line-input
    &:hover
      cursor: pointer!important
.click-icon
  position: relative
  &:before
    content: ""
    position: absolute
    right: 0
    top: 5px
    background-image: url(../img/click.svg)
    width: 25px
    height: 25px
    background-repeat: no-repeat
    background-size: cover

