.drawer
  position: fixed
  top: 0
  right: 0
  width: 100%
  height: 100vh
  z-index: 6
  transition: .5s
  //background: #000
  //background-color: initial
  +mq-lg
    background-color: initial
  &__
    &overlay
      //background-color: #00000080
      position: absolute
      top: 0
      right: 0
      height: 100%
      width: 100%
      z-index: 9
    &sidebar
      background: #000
      position: absolute
      width: 100%
      transition: 1s
      z-index: 12
      height: 100%
      top: 0
      padding-top: 90px
      +mq-lg
        padding-top: 140px
      +mq-xl
        overflow: hidden
        overflow-y: auto
      &--
        &inactive
          left: -100%
        &isactive
          left: 0
  //background-color: red
  &--
    &inactive
      opacity: 1
      visibility: hidden
      //display: none
    &isactive
      opacity: 1
      visibility: visible
      .wrap-header
        opacity: 1
        //width: 100%
        display: block
.wrap-header
  //width: 100%
  display: block
  //выезд
  opacity: 1
  +mq-till-xl
    overflow: hidden
    overflow-y: auto
    height: 100%
    .bottom_head
      padding-bottom: 120px

.top-menu-wrap
  +mq-till-sm
    justify-content: center
    margin: 0
    padding: 0
      top: 50px
  padding-top: 50px
  @media (min-width: 1800px)
    padding-top: 100px
.menu-link
  padding: 20px 0
  +mq-md
    padding: 20px 0
  &.grey
    opacity: 0.4
  &.active
    color: white
  &.white
    color: white
.header-list
  align-items: center
  +mq-md
    align-items: flex-start
    align-content: center
