::-webkit-scrollbar
  -webkit-appearance: none

::-webkit-scrollbar:vertical
  width: 10px

::-webkit-scrollbar:horizontal
  height: 10px

::-webkit-scrollbar-thumb
  background-color: black
  border-radius: 10px
  border: 2px solid #9B9B9B

::-webkit-scrollbar-track
  border-radius: 10px
  background-color: #9B9B9B
