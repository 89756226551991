.radio-wrapper
  label
    display: block
  @supports(-webkit-appearance: none) or (-moz-appearance: none)
    input[type='checkbox']
      --focus: 2px rgba(39, 94, 254, .1)
      --disabled-inner: #E1E6F9
      --border-hover: #2DB2DC
      --active-inner: #2DB2DC
      --background: #FFFFFF
      --disabled: #F6F8FF
      --active: #0E0E0E
      --border: #FFFFFF
      transition: background .3s, border-color .3s, box-shadow .2s
      box-shadow: none !important
      -webkit-appearance: none
      -moz-appearance: none
      display: inline-block
      vertical-align: top
      position: relative
      cursor: pointer
      outline: none
      min-height: 24px
      min-width: 24px
      margin: 0
      &.errors
        border: 3px solid #FF0000
      //border: 3px solid white
      //+mq-md
      &:disabled
        --b: var(--disabled)

        cursor: not-allowed
        opacity: .9
        &:checked
          --b: var(--disabled-inner)
          --bc: var(--border)
        & + label
          padding-left: 15px
          cursor: not-allowed
      &:checked
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2)
        --d-o: .3s
        --d-t: .6s
        --border: #0E0E0E
      &:after
        position: absolute
        display: block
        left: 0
        top: 0
        content: ''
      &:hover
        &:not(:checked)
          &:not(:disabled)
            --bc: #FF0000
      &:not(.switch)
        width: 24px
        height: 24px
        &:checked
          --o: 1
        &:after
          opacity: var(--o, 0)
      & + label
        cursor: pointer
        padding-left: 20px
        +mq-md
          padding-left: 40px
    input[type='checkbox']
      &:not(.switch)
        background-color: white
        &:after
          border: 2px solid $text-3
          transform: rotate(var(--r, 20deg))
          border-left: 0
          border-top: 0
          height: 11px
          width: 7px
          left: 8px
          top: 4px
        &:checked
          border: 0
          background-color: #0E0E0E
          --r: 43deg
      &.switch
        background-color: #dcdcdc
        border: 1px solid $text-3
        box-shadow: none
        width: 24px
        &:after
          //box-shadow: 0 6px 2px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.16), 0 6px 16px rgba(0, 0, 0, 0.15)
          background-color: $text-3
          transform: translateX(var(--x, 0))
          border-radius: 50%
          height: 16px
          width: 16px
          left: 2px
          top: 1px
        &:disabled
          opacity: .7
          background-color: var(--disabled)
        // &:checked
        //   &:after
        &:checked
          background-color: #0E0E0E
          --x: 17px
  .help--type_error
    bottom: -7px
  label
    padding-left: 15px
    transition: 0.5s
    cursor: pointer
    font-family: 'Unbounded-Light', sans-serif
    color: #8D8D8D
    font-weight: 300
    line-height: 200%
    font-size: 10px
    +mq-md
      font-size: 14px
    a
      color: #FFF
      font-family: 'Unbounded-Light', sans-serif
      font-weight: 300
      line-height: 200%
      text-decoration-line: underline
      font-size: 10px
      +mq-md
        font-size: 14px
    &:hover
      color: #FFF
