.#{$GRID_COLS_PREFIX}cols
  $grid-cols-columns-count: 12
  height: 100%
  &--
    &inherit
      display: inherit
    @for $i from 1 through $grid-cols-columns-count
      &#{$i}
        $columns: grid-cols($i, $total: $grid-cols-columns-count)
        &-mac
          +mq-mac
            width: $columns
            flex: none
    &n_height
      height: auto

.g-row
  &--
    &space_3xl
      +mq-adaptive
        --grid-space: 32px
    &space_4xl
      +mq-adaptive
        --grid-space: 42px
    &space_5xl
      +mq-adaptive
        --grid-space: 54px
    &space_8xl
      +mq-adaptive
        --grid-space: 88px
    &space_10xl
      --grid-space: 32px
      +mq-mac
        --grid-space: 100px


    &col-rev
      +mq-adaptive
        flex-direction: column-reverse
    &col
      +mq-adaptive
        flex-direction: column
    &row
      +mq-adaptive
        flex-direction: row
    &row-rev
      +mq-adaptive
        flex-direction: row-reverse
    &col-rev
      +mq-adaptive
        flex-direction: column-reverse
