.lang
  opacity: 0.3
  &.actives,
  &.active
    opacity: 1
    position: relative
    &:before
      content: ''
      position: absolute
      bottom: -6px
      left: 0
      height: 3px
      width: 100%
      background-color: #757575

