.v-swiper
  &--
    &tecnologies,
    &services
      +mq-md
        overflow: unset!important
        padding-right: 70px!important
        clip-path: inset( -100vw -100vw -100vw 0 )
      .swiper-slide
        border: 0!important
.halls-slider-top
  .swiper-button-group
    width: 132px
    left: 0
    right: 0
    margin: auto
.halls-slider-thumbs
  //.swiper-wrapper
  //  transform: none !important
  .swiper-slide
    width: auto !important
  .swiper-slide-active
    .ds-caption
      opacity: 1
.swiper-button-group
  display: flex
  justify-content: space-between
  align-items: center
  .swiper-next,
  .swiper-prev
    background-image: url('../img/arrow-right.svg')
    display: flex
    height: 39px
    width: 39px
    justify-content: center
    align-items: center
    border-radius: 5px
    +mq-xl
      &:hover
        cursor: pointer
    &.swiper-button-disabled
      opacity: 0.3
    &:after
      display: none!important
      content: ''
  .swiper-prev
    transform: rotate(-180deg)

