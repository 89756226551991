.nested-enter-active
  transition: all 0.3s ease-in-out

.nested-leave-active
  transition: all 0.3s ease-in-out
  transition-delay: 0.25s

.nested-enter-from, .nested-leave-to
  transform: translateY(30px)
  opacity: 0

.nested-enter-active .inner, .nested-leave-active .inner
  transition: all 0.3s ease-in-out

.nested-enter-active .inner
  transition-delay: 0.25s

.nested-enter-from .inner, .nested-leave-to .inner
  transform: translateY(30px)
  opacity: 0.001

.fade-enter-active, .fade-leave-active
  transition: opacity .5s

.fade-enter, .fade-leave-to
  opacity: 0
.el-cards-outlainer
  margin: 15px 0
  +mq-mac
    margin: 50px 0
