@import "variables-panel.scss"
@import "fonts"
@import "dermis"
@import "elements"
@import "component"
@import "swiper/swiper-bundle"
@import "aos/src/sass/aos"
@import 'vue-select/dist/vue-select'
html
  scroll-behavior: smooth
.vue-app
  min-height: 100vh
  background: #0E0E0E
  overflow-x: hidden
  display: flex
  flex-direction: column
  justify-content: space-between
  position: relative
  -webkit-text-size-adjust: 100%
