.el-google
  position: relative
  width: 100%
  margin-top: 60px
  margin-bottom: 35px
  +mq-md
    margin-bottom: 60px
  +mq-xl
    margin-bottom: 90px
    margin-top: 120px
    height: 1049px
  &__
    &top
      background-color: white
      width: auto
      //height: 111px
      padding: 5px 10px
      position: relative
      //bottom: -111px
      z-index: 10
      max-width: 300px
    &map
      display: block
      width: 100%
      height: 572px
      +mq-md
        height: 700px
      +mq-xl
        height: 100%
    &form
      z-index: 5
      width: 100%
      height: auto
      left: 0
      padding: 30px
      background-color: rgba(0, 0, 0, 08)
      +mq-md
        background-color: rgba(14, 14, 14, 0.9)
        padding: 50px
      +mq-xl
        padding: 80px
        max-width: 858px
        left: 80px
        top: 50%
        transform: translateY(-50%)
        position: absolute
.img--map-icon
  width: 32px
  height: 32px

.gm-style .gm-style-iw-c
  max-width: 300px!important
