.header
  height: auto
  width: 100%
  position: relative
  z-index: 15
  padding: 50px 0
  +mq-md
    padding: 58px 0

//#burger
//  position: relative
//  z-index: 10
//  +mq-xl
//    &:hover
//      cursor: pointer
//  path
//    transition: 1s
  //&.menus
    path:nth-of-type(1)
      animation: burger1 2s ease-out infinite
    path:nth-of-type(2)
      animation: burger2 2s ease-out infinite
    path:nth-of-type(3)
      display: block
      animation: burger3 2s ease-out infinite
//  &.closes
//    path:nth-of-type(1)
//      transform-origin: 3px
//      d: path("M0 0H22V3H0V0Z")
//      transform: translate(-15%, 0%) rotate(45deg)
//    path:nth-of-type(2)
//      transform: translate(-10%, 20%) rotate(-45deg)
//      transform-origin: -3px
//      d: path("M0 16H22V19H0V16Z")
//    path:nth-of-type(3)
//      display: none
//
//@keyframes burger1
//  0%
//    d: path("M0 0H15V3H0V0Z")
//  50%
//    d: path("M0 0H22V3H0V0Z")
//  100%
//    d: path("M0 0H15V3H0V0Z")
//
//@keyframes burger2
//  0%
//    d: path("M0 16H15V19H0V16Z")
//
//  50%
//    d: path("M0 16H22V19H0V16Z")
//
//  100%
//    d: path("M0 16H15V19H0V16Z")
//
//@keyframes burger3
//  0%
//    d: path("M0 8H22V11H0V8Z")
//  50%
//    d: path("M0 8H15V11H0V8Z")
//  100%
//    d: path("M0 8H22V11H0V8Z")

.burger-menu
  display: flex
  flex-direction: column
  width: 22px
  height: 19px
  position: relative
  z-index: 10
  justify-content: space-between
  *
    transition: 1s
  +mq-xl
    &:hover
      cursor: pointer
  div
    width: 22px
    height: 3px
  div:nth-of-type(1)
    animation: burger11 2s ease-out infinite
    background-color: white
  div:nth-of-type(2)
    width: 15px
    background-color: #757575
    animation: burger22 2s ease-out infinite
  div:nth-of-type(3)
    background-color: white
    animation: burger33 2s ease-out infinite
  &.active
    div:nth-of-type(1)
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%) rotate(-45deg)
      animation: unset
    div:nth-of-type(2)
      display: none
    div:nth-of-type(3)
      animation: unset
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%) rotate(45deg)





@keyframes burger11
  0%
    width: 22px
  50%
    width: 15px
  100%
    width: 22px
@keyframes burger22
  0%
    width: 15px
  50%
    width: 22px
  100%
    width: 15px
@keyframes burger33
  0%
    width: 22px
  50%
    width: 15px
  100%
    width: 22px
