.#{$GRID_CONTAINER_PREFIX}container
  +mq-till-lg
    --grid-cell-space-half: 15px
  +mq-xl
    --grid-cell-space-half: 15px
  &--
    &fit_
      &auto_mac
        +mq-mac
          max-width: 1920px
          //padding-left: 100px
          //padding-right: 100px
      &auto_xl
        +mq-xl
          max-width: 100%
          padding-left: 5.208vw
          padding-right: 5.208vw
          //padding-left: 50px
          //padding-right: 50px

      &mac-mac
        +mq-mac
          max-width: $mq-size-mac

