#cards-java
  path:nth-of-type(1)
    transform-origin: center
  +mq-xl
    &:hover
      .cards-java
        path:nth-of-type(1)
          transform: rotate(15deg)

#cards-magento
  path:nth-of-type(2)
    transform-origin: center 31%
  +mq-xl
    &:hover
      .cards-magento
        path:nth-of-type(2)
          transform: rotate(15deg)

#cards-cloud
  +mq-xl
    &:hover
      .cards-cloud
        path:nth-of-type(5),
        path:nth-of-type(6)
          transform: translateY(-15%)
        path:nth-of-type(7)
          transform: translateY(15%)
#cards-sql
  path:nth-of-type(5),
  path:nth-of-type(6)
    opacity: 0
  +mq-xl
    &:hover
      .cards-sql
        path:nth-of-type(3),
        path:nth-of-type(4)
          opacity: 0
        path:nth-of-type(5),
        path:nth-of-type(6)
          opacity: 1
