.page-data-privacy
  .gap-wrap
    gap: 30px
    +mq-xl
      gap: 15.781vw
  .wrap
    display: flex
    flex-direction: column
    gap: 50px
    padding-top: 50px
  .page-nav--mobile,
  .page-nav--desktop
    max-width: 320px
    width: 100%
    height: 100%
    .page-auto-nav__items
      display: inline-flex
      flex-direction: column
      align-items: flex-start
      gap: 30px
      @media (min-width: 1500px)
        gap: 45px
      li
        &.active
          a
            opacity: 1
      li, a
        color: #FFF
        font-family: 'Unbounded-Regular', sans-serif
        font-weight: 400
        line-height: 180%
        text-transform: uppercase
        font-size: 12px
        +mq-md
          font-size: 13px
        +mq-lg
          font-size: 16px
      a
        opacity: 0.5
        transition: 0.5s
        text-decoration: none
        &:hover
          cursor: pointer
          opacity: 1
  .page-nav--mobile
    padding-top: 45px
    +mq-lg
      display: none
  .page-nav--desktop
    +mq-till-lg
      display: none
    .page-auto-nav
      padding-top: 50px
      position: sticky
      top: 0
