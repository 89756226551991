.el
  &--
    &p
      &_
        @each $size, $value in $type-page-indent_group
          &r_#{$size}
            +mq-both-adaptive
              padding-right: $value
          &t_#{$size}
            +mq-both-adaptive
              padding-top: $value
          &l_#{$size}
            +mq-both-adaptive
              padding-left: $value
          &b_#{$size}
            +mq-both-adaptive
              padding-bottom: $value
          &f_#{$size}
            +mq-both-adaptive
              padding: $value
          &h_#{$size}
            +mq-both-adaptive
              padding-top: $value
              padding-bottom: $value
          &v_#{$size}
            +mq-both-adaptive
              padding-right: $value
              padding-left: $value
    &m
      &_
        @each $size, $value in $type-page-indent_group
          &r_#{$size}
            +mq-both-adaptive
              margin-right: $value
          &t_#{$size}
            +mq-both-adaptive
              margin-top: $value
          &l_#{$size}
            +mq-both-adaptive
              margin-left: $value
          &b_#{$size}
            +mq-both-adaptive
              margin-bottom: $value
          &f_#{$size}
            +mq-both-adaptive
              margin: $value
          &h_#{$size}
            +mq-both-adaptive
              margin-top: $value
              margin-bottom: $value
          &v_#{$size}
            +mq-both-adaptive
              margin-right: $value
              margin-left: $value
    &inherit
      display: inherit
    &bl
      display: block
    &inline
      display: inline
