.intro-wrap
  position: relative
  width: 599.195px
  height: 346.233px
  left: 50%
  transform: translateX(-50%)
  +mq-md
    left: unset
    transform: unset
    width: 86.615vw
    height: 45.729vw
    right: -22%
    position: absolute
    top: 50px
  +mq-xl
    top: 2.604vw
  &__
    &video
      width: 100%
      height: 100%
