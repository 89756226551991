.VuePagination
  .VuePagination__pagination
    display: flex
    justify-content: space-around
    color: white
  .VuePagination__pagination-item-prev-chunk,
  .VuePagination__pagination-item-next-chunk,
  .VuePagination__count
    display: none
.pagination-item
  cursor: pointer
  &.is-active
    .ds-caption--op-4
      opacity: 1
.el-pagination
  padding-top: 50px
  +mq-xl
    padding-top: 25px
.pagination-item--arrow
  display: flex
  width: 39px
  height: 39px
  position: relative
  &.right,
  &.left
    &:before
      content: ''
      position: absolute
      top: 50%
      transform: translateY(-50%)
      width: 1px
      height: 26px
      background: #363636
  &.right:before
    left: -26px
  &.left:before
    right: -26px
  svg
    *
      transition: 0.5s
  &:hover
    svg
      g
        opacity: 1
