.social-wrap
  position: relative
  display: flex
  +mq-xl
    &:hover
      img
        cursor: pointer
        transform: scale(1.2)
  &__
    &link
      z-index: 5
      @extend .pos_abs
    &img
      transition: 0.5s
      object-fit: contain
      width: 32px
      height: 32px
      +mq-md
        width: 20px
        height: 20px
