$type-page-indent_group: () !default;
$type-page-indent_group: (
  "xxl": 100px,
  "4xl": 80px,
  "3xl": 75px,
  "2xl": 70px,
  "1xl": 65px,
  "xl": 60px,
  "3lg": 55px,
  "2lg": 50px,
  "1lg": 45px,
  "lg": 40px,
  "1md": 35px,
  "md": 30px,
  "1sm": 25px,
  "sm": 20px,
  "2xs": 15px,
  "1xs": 10px,
  "xs": 5px,
  "none": 0,
);
$font-height-group: () !default;
$font-height-group: map-merge($font-height-group, (
        "10xl": 97px,
        "7xl": 75px,
        "6xl": 60px,
        "5xl": 50px,
        "4xl": 45px,
        "3xl": 38px,
        "xl": 32px,
        "lg": 28px,
        "2md": 1.5,
        "md": 26px,
        "sm": 24px,
        "xs": 22px,
        "1xs": 20px,
        "zero": 0,
        "def": 1,
        "auto": auto,
        "inh": inherit,
        "200": 200%,
        "180": 180%,
        "130": 130%,
));
